
import Forms from "./form/index";
import SubHeader from "@/components/SubHeader"
import packDetails from "@/assets/icons/svg/tax-details.svg"
import assignTenant from "@/assets/icons/svg/assign-tenant.svg"
export default  {
    name: "creditPack",
    data: ()=>{
        return {
            packDetailsUpdate: null,
            creditPackOptions: [
                {
                    name: "credit_pack_details",
                    label: "",
                    values: {},
                    hasErrors: false,
                    disabled: false,
                    logo: packDetails,
                    show: true,
                },
                {
                    name: "assign_to_tenant",
                    label: "Assigned Tenants",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: assignTenant,
                    show: true,

                },
            ],
            selectedSection: "credit_pack_details",
            FormComponents: Forms,
            customBreadCrumbs: []
        }
    },
    components: {
        SubHeader
    },
    computed: {
        activeForm: {
            get() {
                return this.creditPackOptions.find((el) => el.name === this.selectedSection);
            },
            set(val) {
                console.log(val, ">>>val");
            },
        },
    },
    mounted() {
        if (this.$route.params.id && !this.$route.query.clone) {
            this.creditPackOptions.forEach((res)=>{
                res.disabled = false
            })
        }
        if (this.$route.params.id) {
            this.customBreadCrumbs?.push({name: 'Modify Credit Pack'})
            this.creditPackOptions[0].label = 'Modify Credit Pack'
        } else {
            this.customBreadCrumbs?.push({name: 'Create Credit Pack'})
            this.creditPackOptions[0].label = 'Credit Pack Details'
        }
    },
    methods: {
        sectionClick(data) {
            this.selectedSection = data.name
            this.packDetailsUpdate +=  1;
        },
        handleNext(data) {
            this.packDetailsUpdate +=  1;
            this.selectedSection = data.nextSection
            this.$router.push({params:{id:data.packId}})
            this.creditPackOptions.forEach((res)=>{
                if (res.name == data.nextSection) {
                    res.disabled = false
                }
            })
        }
    }
}