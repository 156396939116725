<template>
  <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
    <div
      class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
    >
      {{ editMode ? "Modify Credit Pack" : "Credit Pack Details" }}
    </div>
    <div
      class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
      v-if="loadingCurrencies"
    >
      <Loader />
    </div>
    <!-- Form -->
    <div class="flex gap-9 py-3 px-6" v-else>
      <div class="w-6/12">
        <FormulateForm>
          <!-- Static fields -->
          <div class="grid">
            <FormulateInput
              :disabled="assignedTenants"
              class="w-full"
              :class="{'disableInput' : assignedTenants}"
              label="Pack Name"
              validation="required"
              placeholder="Pack name"
              type="text"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
              v-model="packInformation.pack_name"
            />
            <FormulateInput
              :disabled="assignedTenants"
              class="w-full creditNumber"
              :class="{'cursor-not-allowed' : assignedTenants}"
              label="Credits"
              validation="required"
              placeholder="credits"
              type="number"
              :element-class="
                (context, classes) => [`flex-1 min-w-full ${assignedTenants ? 'disableInput' : ''}`].concat(classes)
              "
              v-model="packInformation.no_of_credit"
              @keypress="isNumber"
              @input="(value) => handleCreditNumber(value, 'no_of_credit')"
              />
              <span v-if="hasError.error_filed_name == 'no_of_credit' && hasError.is_error" class="text-red-500 text-xs">{{ hasError.error_msg }}</span>
            <h1 class="mt-3">Validity in Days <span class="red">*</span></h1>
            <label class="input-group customInputs">
              <span class="bg-primary-300 h-2">Days</span>
              <input
                :disabled="assignedTenants"
                type="number"
                placeholder="days"
                class="input input-bordered input-sm w-full h-2 validityDate"
                :class="{'cursor-not-allowed' : assignedTenants}"
                v-model="packInformation.validity"
                @keypress="isNumber"
                @input="(e) => handleCreditNumber(e.target.value, 'validity')"
              />
            </label>
            <span v-if="hasError.error_filed_name == 'validity' && hasError.is_error" class="text-red-500 text-xs">{{ hasError.error_msg }}</span>
            <h1 class="mt-4 w-full">Pack Description</h1>
            <textarea
              placeholder="description"
              class="textarea textarea-bordered textarea-lg w-full"
              v-model="packInformation.pack_description"
            ></textarea>
          </div>
        </FormulateForm>
      </div>
       <!-- Dynamic fields -->
       <div class="grid w-6/12 mt-2"> 
           <div v-for="(currency, index) in masterCurrencies" :key="currency.id" class="mb-3">
            <h1>{{currency.currency_name + " "}} <span class="red">*</span></h1>
            <label class="input-group customInputs">
              <span class="bg-primary-300 h-2 py-1.5">{{currency.currency_symbol}}</span>
              <input
                type="number"
                placeholder="price"
                class="input input-bordered input-sm w-full h-2 py-1.5"
                v-model="currency.price"
                @keypress="isNumber"
                @input="(e) => handleCreditPrice(e.target.value, index)"
              />
            </label>
            <span v-if="currency.hasPriceError" class="text-red-500 text-xs">{{ currency.errorMsg }}</span>
           </div>
        </div>
    </div>
      <!-- -->
    <!-- </div> -->
    <!-- Action buttons -->
    <div class="grid ml-6 mr-6 gap-2" v-if="editMode && !loadingCurrencies">
      <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700" />
      <span>Actions <span class="red">*</span></span>
      <div class="flex gap-7">
        <div class="flex gap-2">
          <input
            :checked="status.active"
            type="checkbox"
            class="checkbox mt-1 checkbox-sm"
            v-model="status.active"
            @change="status.deactivate = false"
          />
          <label class="cursor-pointer label">
            <span class="label-text">Activate</span>
          </label>
        </div>

                  <div class="flex gap-2">
                    <input
                      :checked="status.deactivate"
                      type="checkbox"
                      class="checkbox mt-1 checkbox-sm"
                      v-model="status.deactivate"
                      @change="status.active = false"
                    />
                    <label class="cursor-pointer label">
                      <span class="label-text">Deactivate</span>
                    </label>
                  </div>
         </div>
         <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700">
    </div>
    <!-- -->
    <div class="flex my-4 justify-end w-full pr-2">
            <Button
      :disabled="!changeMade"     
      :loader="creatingPack"
      :text="editMode && !this.$route.query.clone ? 'Update': 'Save & Next'"
      type="primary"
      @click="createCreditPack()"
    />
    </div>
  </div>
</template>

<script>
import {
  getCurrenciesList,
  createPack,
  getPackDetails,
  getCurrencies,
  updateCreditPack,
  getAssignedTenants,
} from "../services";
import Loader from "@shared/loader";
import Button from "@shared/components/button";
import { isNumber } from "@/plugins/functions";

export default {
  name: "creditPackInformation",
  data: () => {
      return {
          changeMade: false,
          creatingPack: false,
          masterCurrencies: [],
          loadingCurrencies: false,
          packInformation: {
            pack_name: null,
            no_of_credit: null,
            validity: null,
            pack_description: null,
            prices: []
          },
          status: {
            active: false,
            deactivate: false
          },
          showErrors: false,
          assignedTenants: false,
          hasError: {error_filed_name: '', is_error: true, error_msg: ''}
      }
  },
  components: {
    Loader,
    Button,
  },
  props: {},
  computed: {
    editMode() {
      return this.$route.params.id ? true : false;
    },
    // tableHeader() {
    //   return this.$route.params.id ? 'Modify Credit Pack' : 'Credit Pack Details'
    // }
  },
  watch:{
    packInformation: {
      deep: true ,
      handler(){
        if(!this.loadingCurrencies){
          this.changeMade = true
        }
      }
    },
    masterCurrencies:{
      deep: true,
      handler(){
        if(!this.loadingCurrencies){
            this.changeMade = true
          }
      }
    },
    status:{
      deep: true,
      handler(){
        if(!this.loadingCurrencies){
            this.changeMade = true
          }
      }
    },

  }, 
  async mounted() {
    this.loadingCurrencies = true;
    if (this.editMode) {
      await this.setCreditPackDetails();
    } else {
      await this.setMasterCurrencies();
    }
    this.loadingCurrencies = false;
  },
  methods: {
    isNumber,
    async setCreditPackDetails() {
      const { data } = await getPackDetails(this.$route.params.id);
      const savedCurrencies = data.prices;
      let assignedTenants = await getAssignedTenants(this.$route.params.id);
      const { tenant_count } = assignedTenants.data.data;
      this.assignedTenants = tenant_count > 0 ? true : false;
      let { name, validity, description, pack_credits, active } = data;
      this.packInformation = {
        pack_name: this.$route.query?.clone ? name + "_clone" : name,
        validity,
        no_of_credit: pack_credits,
        pack_description: description,
      };
      if (active) {
        this.status.active = true;
      } else {
        this.status.deactivate = true;
      }
      const masterCurrenciesData = await getCurrencies();
      const currencies = masterCurrenciesData.data.data.map((item) => {
        const savedCurrency = savedCurrencies.find(
          (res) => res.currency_id == item.id
        );
        if (savedCurrency) {
          return {
            id: item.id,
            currency_name: item.currency_name,
            currency_symbol: item.currency_symbol,
            price: savedCurrency.value,
            base_currency: item.base_currency,
          };
        } else {
          return {
            id: item.id,
            currency_name: item.currency_name,
            currency_symbol: item.currency_symbol,
            price: 0,
            base_currency: item.base_currency,
          };
        }
      });
      this.masterCurrencies = currencies.filter((res) => res != undefined);
      // this.masterCurrencies = this.masterCurrencies.sort((a, b) =>
      //   a.base_currency === true ? -1 : b.base_currency === true ? 1 : 0
      // );
      const indexToShift = this.masterCurrencies.findIndex((obj) => obj.base_currency === true);
      if (indexToShift !== -1) {
        const shiftedObject = this.masterCurrencies[indexToShift];
        this.masterCurrencies.splice(indexToShift, 1);
        this.masterCurrencies.unshift(shiftedObject);
      }
    },
    handleCreditNumber(value, name) {
        if (name == 'no_of_credit') {
          if (this.showErrors && value && parseInt(value) != value) {
            this.hasError = {error_filed_name: 'no_of_credit', is_error: true, error_msg: 'Credits can not be float!'}
          } else {
            this.hasError = {error_filed_name: 'no_of_credit', is_error: false, error_msg: ''}
          }
        }
        if (name == 'validity') {
          if (this.showErrors && value && parseInt(value) != value) {
            this.hasError = {error_filed_name: 'validity', is_error: true, error_msg: 'validity can not be float!'}
          } else {
            this.hasError = {error_filed_name: 'validity', is_error: false, error_msg: ''}
          }
        }
      },
    async setMasterCurrencies() {
      const { data } = await getCurrenciesList();
      this.masterCurrencies = data.data.filter(
        (res) => res.master_currency == true
      );
      this.masterCurrencies = this.masterCurrencies.sort((a, b) =>
        a.base_currency === true ? -1 : b.base_currency === true ? 1 : 0
      );
      this.masterCurrencies.forEach((res) => {
        res.price = null;
      });
    },
    async createCreditPack() {
        this.showErrors = true;
        this.creatingPack = true;
        let fieldsDone = true;
        this.changeMade = false;
        let validPrices = true;
        this.packInformation.prices = this.masterCurrencies.map((res)=>{
          return {
            currency_id: res.id,
            price: res.price
          }
        })
        let noDesc = {};
        Object.assign(noDesc, this.packInformation);
        delete noDesc['pack_description']
        if (Object.values(noDesc).some(res=> res === null) || 
            Object.values(noDesc).some(res=> res === "")) {
          fieldsDone = false
        }
        this.packInformation.prices.forEach((res)=>{
          if(res.price === null || res.price === '' || !res.currency_id) {
            fieldsDone = false
          }
        })
        this.masterCurrencies.forEach(mstrcurr => {
          if (parseInt(mstrcurr.price) < 1 ) {
            mstrcurr.hasPriceError = true;
            mstrcurr.errorMsg = 'Price cannot be less then 1'
            this.creatingPack = false
            validPrices = false;
            return;
          } else {
            mstrcurr.hasPriceError = false;
            mstrcurr.errorMsg = ''
          }
        })
        if ((!this.status.active && !this.status.deactivate) && this.$route.params.id) {
          fieldsDone = false
        }
        if (parseInt(this.packInformation.no_of_credit) != this.packInformation.no_of_credit) {
          this.hasError = {error_filed_name: 'no_of_credit', is_error: true, error_msg: 'Credits can not be float!'}
          this.creatingPack = false
          return;
        } else {
          this.hasError = {error_filed_name: 'no_of_credit', is_error: false, error_msg: ''}
        }
        if (parseInt(this.packInformation.validity) != this.packInformation.validity) {
          this.hasError = {error_filed_name: 'validity', is_error: true, error_msg: 'validity can not be float!'}
          this.creatingPack = false
          return;
        } else {
          this.hasError = {error_filed_name: 'validity', is_error: false, error_msg: ''}
        }
        if (!validPrices) {
          this.creatingPack = false;
          return;
        }
        if (!fieldsDone) {
          this.$toast.error("Please fill all required fields")
          this.creatingPack = false
          return
        }
        if (this.editMode && !this.$route.query.clone) {
          this.packInformation['active'] = this.status.active ? true : false
          let payload = {}; 
          Object.assign(payload,this.packInformation);
          if(this.assignedTenants) {
            delete payload.pack_name
            delete payload.validity
          }
          try {
            const { data } = await updateCreditPack(payload, this.$route.params.id)
            this.$toast.success(data?.message ? data?.message : "Credit pack updated successfully.")
            let nextTab = {
              name: "assign_to_tenant",
              label: "Assigned Tenants",
              values: {},
              hasErrors: false,
              disabled: true,
              show: true,    
            }
            this.$emit('sectionClick', nextTab)
            this.creatingPack = false
          } catch (error) {
            if (Array.isArray(error?.response?.data?.detail)) {
              this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
            } else {
              this.$toast.error(error?.response?.data?.detail); 
            }
            console.log(error);
            this.creatingPack = false
          }
        }
        else {
          try {
            const {data} = await createPack(this.packInformation)
            this.$toast.success("Credit pack created successfully.")
            const result = {
              packId: data.id,
              nextSection: "assign_to_tenant"
            }
            this.$emit("handleNextSection",result)
            this.creatingPack = false
          } catch (error) {
            console.log(error?.response?.data);
            this.creatingPack = false
            if (Array.isArray(error?.response?.data?.detail)) {
              this.$toast.error(error?.response?.data?.detail?.[0]?.msg || 'Something went Wrong');
            } else {
              this.$toast.error(error?.response?.data?.detail || 'Something went Wrong');
            }
          }
        }
    }
  },
};
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.red {
  color: #ff0000;
}
.h-2 {
  height: 2.3rem;
}
.formulate-input[data-classification="text"] input {
  border-radius: 0.5rem;
}
.input-group {
  margin-bottom: 1.5rem;
}
.customInputs {
  margin-bottom: 0 !important;
}
.validityDate {
  height: 37px;
  border: 1px solid var(--theme-color-box-stroke);
  border-radius: .3rem;
}
.creditNumber {
  margin-bottom: 0 !important;
}
::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
}
</style>
