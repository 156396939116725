import axios from "@/axios"

export async function getCurrenciesList() {
    const url = "/currency?page=1&limit=50"
    return await axios.get(url)
}

export async function createPack(payload) {
    const url = "/credit-pack"
    return await axios.post(url,payload)
}

export async function getTenants() {
    const url = "/tenant"
    return await axios.get(url)
}

export async function getCurrencies() {
    const url = "/currency/master"
    return await axios.get(url)
}

export async function getPackDetails(packId) {
    const url = `/credit-pack/${packId}`
    return await axios.get(url)
}

export async function getAssignedTenants(packId) {
    const url = `/credit-pack/${packId}/tenants`
    return await axios.get(url)
}

export async function assignTenantsPacks(payload) {
    const url = `/credit-pack/tenants`
    return await axios.post(url, payload)
}

export async function updateCreditPack(payload, packId) {
    const url = `/credit-pack/${packId}`
    return await axios.put(url,payload)
}